import { computed } from '@angular/core';

import { patchState, signalStoreFeature, withComputed, withMethods, withState } from '@ngrx/signals';

import { produce } from 'immer';

import { CollectionState } from './collection-state.type';
import { Ordering } from './ordering.type';
import { Paging } from './paging.type';
import { selectableCollectionItemGuard } from './selectable-collection-item.type';

export const withCollection = <CollectionItem, ItemId extends 'id' extends keyof CollectionItem ? CollectionItem['id'] : unknown>(
  collectionState: CollectionState<CollectionItem>,
) =>
  signalStoreFeature(
    withState(collectionState),
    withMethods((state) => ({
      changePaging(paging: Paging): void {
        patchState(state, (state) =>
          produce(state, (draft) => {
            draft.listParams.paging = paging;
          }),
        );
      },
      resetPaging(): void {
        patchState(state, (state) =>
          produce(state, (draft) => {
            draft.listParams.paging.pageIndex = 1;
          }),
        );
      },
      resetSelection(): void {
        patchState(state, { selection: new Map() });
      },
      search(searchValue: string): void {
        patchState(state, (state) =>
          produce(state, (draft) => {
            draft.listParams.searchValue = searchValue;
          }),
        );
      },
      setAllItemsOnPageSelection(selected: boolean, unselectablePredicate?: (item: CollectionItem) => boolean): void {
        patchState(state, ({ list, selection }) => {
          const changedSelection = new Map(selection);

          for (const item of list) {
            if (selectableCollectionItemGuard(item) && (!unselectablePredicate || !unselectablePredicate(item))) {
              selected ? changedSelection.set(item.id as ItemId, item) : changedSelection.delete(item.id as ItemId);
            }
          }

          return { selection: changedSelection };
        });
      },
      setIdsToExclude(ids: number[]): void {
        patchState(state, (state) =>
          produce(state, (draft) => {
            draft.listParams.excludeIds = ids;
          }),
        );
      },
      sort(ordering: Ordering): void {
        patchState(state, (state) =>
          produce(state, (draft) => {
            draft.listParams.ordering = ordering;
          }),
        );
      },
      toggleItemSelection(item: CollectionItem): void {
        patchState(state, ({ selection }) => {
          const changedSelection = new Map(selection);

          if (selectableCollectionItemGuard(item)) {
            const itemId = item.id as ItemId;
            selection.has(itemId) ? changedSelection.delete(itemId) : changedSelection.set(itemId, item);
          }

          return { selection: changedSelection };
        });
      },
    })),
    withComputed(({ listParams }) => ({
      listFetchParams: computed(() => ({
        excludeIds: listParams.excludeIds ? listParams.excludeIds() : undefined,
        ordering: listParams.ordering(),
        paging: {
          pageIndex: listParams.paging.pageIndex(),
          pageSize: listParams.paging.pageSize(),
        },
        searchValue: listParams.searchValue(),
      })),
    })),
  );
